import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Pedido } from '../models/Pedido';
import { Carrito } from '../models/Carrito';
import { AlertController } from '@ionic/angular';
import { Transportista } from '../models/Transportista';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public cart: Carrito;
  private cartItemCount = new BehaviorSubject<number>(0);
  public carritoChanges = new Subject<Carrito>();
  public contrarrembolso_precio: number;

  constructor(private alertCtrl: AlertController, private apiService: ApiService) {
    this.cart = {
      id: 1,
      precio_totaldist: 0,
      precio_total: 0,
      precio_sin_iva: 0,
      porcentaje_iva: 1.21,
      iva_aplicado: 0,
      gastos_envio: 0,
      gastos_envio_dist: 0,
      pedidos: [],
      subtotal: 0,
      subtotaldist: 0,
      transportista: null,
      fecha_entrega: Date.now(),
      contrarrembolso: false,
      recoger: false,
      pago_tarjeta: false,
      pago_tienda: false,
    }

  }

  public getPedidos(): Pedido[] {
    return this.cart.pedidos;
  }

  public getCarrito() {
    return this.cart;
  }

  public getCartItemCount() {
    let count = 0
    this.cart.pedidos.forEach(element => {
      count += element.cantidad;
    });
    //return this.cartItemCount;
    return count;
  }

  public addPedido(pedido: Pedido, nuevo: boolean): void {
    let added = false;
    if (!nuevo) {
      for (let p of this.cart.pedidos) {
        if (p.producto_id === pedido.producto_id) {
          if (p.marca == pedido.marca && p.modelo == pedido.modelo) {
            if (p.personalizado == pedido.personalizado) {
              p.cantidad += 1;
              added = true;
              break;
            }
          }
        }
      }
    }
    if (!added) {
      this.cart.pedidos.push(pedido);
    }

    this.cart.precio_total += Number(pedido.precio_tienda);
    this.cart.subtotal += Number(pedido.precio_tienda);
    this.cart.precio_sin_iva += Number(pedido.precio_dist);
  


    this.cartItemCount.next(this.cartItemCount.value + 1);

    if (this.cart.transportista && this.cart.precio_total >= this.cart.transportista.precio_gratis) {
      this.cart.precio_total -= Number(this.cart.gastos_envio);
      this.cart.gastos_envio = 0;
      this.cart.envio_gratis = true;
    }
    if (this.cart.transportista && this.cart.precio_sin_iva >= this.cart.transportista.precio_gratis) {
      this.cart.precio_sin_iva -= Number(this.cart.gastos_envio_dist);
      this.cart.gastos_envio_dist = 0;
      this.cart.envio_gratis = true;
    }
    console.log("PEDIDO", pedido);
    this.apiService.setCartStorage(this.cart)

  }

  public async decreasePedido(pedido: Pedido) {
    for (let [index, p] of this.cart.pedidos.entries()) {
      if (p.producto_id === pedido.producto_id) {
        if (p.personalizado == pedido.personalizado) {
          if (p.cantidad == 1) {
            const alert = await this.alertCtrl.create({
              header: 'Borrar producto',
              message: '¿Quieres eliminar el producto del carrito?',
              buttons: [
                {
                  text: 'Cancelar',
                  role: 'cancel',
                  handler: () => {
                  }
                }, {
                  text: 'Aceptar',
                  handler: () => {
                    this.cart.pedidos.splice(index, 1);

                    
                    this.cart.precio_total -= Number(pedido.precio_tienda);
                    this.cart.subtotal -= Number(pedido.precio_tienda);

                    this.cartItemCount.next(this.cartItemCount.value - 1);

                  }
                }
              ]
            });

            await alert.present();

          } else {
            p.cantidad -= 1;



            this.cart.precio_total -= Number(pedido.precio_tienda);
            this.cart.subtotal -= Number(pedido.precio_tienda);


            if (this.cart.transportista && this.cart.precio_total < this.cart.transportista.precio_gratis) {
              if (this.cart.envio_gratis) {
                this.cart.gastos_envio = Number(this.cart.transportista.precio);
                this.cart.precio_total += this.cart.gastos_envio;
                this.cart.envio_gratis = false;
              }

            }
            if (this.cart.transportista && this.cart.precio_sin_iva < this.cart.transportista.precio_gratis) {
              if (this.cart.envio_gratis) {
                this.cart.gastos_envio_dist = Number(this.cart.transportista.precio);
                this.cart.precio_sin_iva += this.cart.gastos_envio_dist;
                this.cart.envio_gratis = false;
              }

            }

            this.cartItemCount.next(this.cartItemCount.value - 1);

          }
        }

      }
    }
    this.apiService.setCartStorage(this.cart)
  }


  public setTransportista(transportista: Transportista): void {
    this.cart.precio_total = this.cart.precio_total - this.cart.gastos_envio;
    this.cart.transportista = transportista;
    this.cart.gastos_envio = Number(transportista.precio);
    this.cart.precio_total += Number(transportista.precio);
    


    this.cart.fecha_entrega = Date.now() + (transportista.tiempo_estimado * 3600000);

    if (this.cart.transportista && this.cart.precio_total >= this.cart.transportista.precio_gratis) {
      this.cart.precio_total -= Number(this.cart.gastos_envio);
      this.cart.gastos_envio = 0;
    }


    this.carritoChanges.next(this.cart);
  }

  public setContrarrembolso(contrarrembolso: boolean): void {
    if(!this.cart.recoger){
    if (contrarrembolso) {
      if (!this.cart.contrarrembolso)
        this.cart.precio_total += this.contrarrembolso_precio;
    }
    else {
      if (this.cart.contrarrembolso)
        this.cart.precio_total -= this.contrarrembolso_precio;
    }}
    this.cart.contrarrembolso = contrarrembolso;
    this.carritoChanges.next(this.cart);
  
  }

  public getContrarrembolsoPrecio(): number {
    return this.contrarrembolso_precio;
  }
  public setRecoger(recoger: boolean): void{
    this.cart.recoger = recoger;
  }
  public setTienda(tienda: boolean): void{
    this.cart.pago_tienda = tienda;
  }
  public setTarjeta(tarjeta: boolean): void{
    this.cart.pago_tarjeta = tarjeta;
  }

  public clearCart(): void {
    this.cart = {
      id: 1,
      precio_total: 0,
      precio_totaldist: 0,
      precio_sin_iva: 0,
      porcentaje_iva: 21,
      iva_aplicado: 0,
      gastos_envio: 0,
      gastos_envio_dist: 0,
      subtotal: 0,
      subtotaldist: 0,
      pedidos: [],
      transportista: null,
      contrarrembolso: false,
      recoger: false,
      pago_tarjeta: false,
      pago_tienda: false,
      fecha_entrega: Date.now()
    };
    this.cartItemCount.next(0);
    this.apiService.setCartStorage(this.cart)
  }

  public async deletePedido(pedido) {
    const alert = await this.alertCtrl.create({
      header: 'Borrar producto',
      message: '¿Quieres eliminar el producto del carrito?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
          }
        }, {
          text: 'Aceptar',
          handler: () => {
            this.cart.pedidos = this.cart.pedidos.filter(x => x.producto_id != pedido.producto_id);

            this.cart.precio_total -= Number(pedido.precio_tienda) * pedido.cantidad;
            this.cart.subtotal -= Number(pedido.precio_tienda) * pedido.cantidad;
           

            this.cartItemCount.next(this.cartItemCount.value - pedido.cantidad);

            if (this.cart.transportista && this.cart.precio_total < this.cart.transportista.precio_gratis) {
              if (this.cart.envio_gratis) {
                this.cart.gastos_envio = Number(this.cart.transportista.precio);
                this.cart.precio_total += this.cart.gastos_envio;
                this.cart.envio_gratis = false;
              }
            }
            this.apiService.setCartStorage(this.cart)
          }
        }
      ]
    });

    await alert.present();
    this.apiService.setCartStorage(this.cart)

  }
}