import { Component, OnInit } from '@angular/core';

import { Platform, Events, NavController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ApiService } from './services/api.service';
import { CartService } from './services/cart.service';
import { User } from './models/User';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { UtilitiesService } from './services/utilities.service';
import { environment } from "../environments/environment";
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Stripe } from '@ionic-native/stripe/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

var cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  public user: User;
  public cart;
  public token: string;
  public whatsapp: boolean = false;

  public pagesAuth = [
    {
      title: 'Categorías',
      url: '/categorias',
      icon: 'list'
    },
    {
      title: 'Carrito',
      url: '/carrito',
      icon: '/assets/imgs/bag.svg'
    },
  
    
    
  ];

  public pages = [
    {
      title: 'Acceder',
      url: '/login',
      icon: 'person'
    },
    
  
    
  ];


  constructor(private platform: Platform,

    private statusBar: StatusBar,
    private apiService: ApiService,
    private push: Push,
    private utilities: UtilitiesService,
    private events: Events,
    private navCtrl: NavController,
    private socialSharing: SocialSharing,
    private stripe: Stripe,
    private cartService: CartService,
    private inAppBrowser: InAppBrowser) {
  }

  /**
   * Nos suscribimos a los cambios dle perfil
   */
  public ngOnInit(): void {
    this.loginImplicito();
    this.prepararStripe();
    this.cartRestore();
    this.events.subscribe('user:login', () => {
      this.token = "token";
      this.pushNotifications();
    });
    this.events.subscribe('activar-whatsapp', (activo: boolean) => {
      this.whatsapp = activo;
    });

    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => {
        if (this.platform.is('ios')) {
          this.statusBar.styleDefault();
        } else {
          this.statusBar.styleBlackOpaque();
        }
        
      });
    }

    this.apiService.userChanges.subscribe((user: User) => {
      this.user = user;
    });

  }

  public async cartRestore(){
    this.cart = await this.apiService.getCartStorage();
    if (this.cart) {
      this.cartService.cart = this.cart;
    }
  }
  /**
   * Configuración de las notificación push
   */
  public pushNotifications(): void {

    const options: PushOptions = {
      android: {
        senderID: environment.senderID,
        icon: 'notification'
      },
      ios: {
        alert: 'true',
        badge: true,
        sound: 'true',
      },
      windows: {},
    }

    const pushObject: PushObject = this.push.init(options);

    pushObject.on('notification').subscribe((notification: any) => {
      if (notification.additionalData.foreground) {
        this.utilities.showAlert('Nueva notificación', `Has recibido una nueva notificación. <br><p><u>Título</u>: ${notification.title}</p><p><u>Descripción</u>: ${notification.message}</p>`);
      }
    });

    pushObject.on('registration').subscribe((registration: any) => {
      const regId = registration.registrationId;
      this.apiService.guardarTokenDeRegistro(regId).subscribe((response) => {
        console.log(response);
      }, (error) => {
        console.log(error);
      })
    });

    pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
  }

  /**
   * Login implícito
   */
  public async loginImplicito() {
    this.token = await this.apiService.getTokenStorage();
    if (this.token) {
      await this.apiService.setTokenToHeaders(this.token);
    } else {
      this.navCtrl.navigateRoot('/login');
    }
  }

  /**
   * Método para cerrar sesión
   */
  public async logout() {
    this.apiService.clearStorage();
    this.apiService.setTokenToHeaders(null);
    this.token = null;
    this.navCtrl.navigateRoot('/login');
  }

  public contactar(): void {
    this.inAppBrowser.create('https://api.whatsapp.com/send?text=Hola&phone=+34644676741', '_system');
  }

  /**
   * Preparamos stripe con su configuración
   */
  public prepararStripe(): void {
    this.stripe.setPublishableKey(environment.stripePublishableKey);
    console.log("PREPARADO");
  }


  public openFb(): void {
    this.inAppBrowser.create('https://www.facebook.com/M%C3%B3vil-360-101655521425313/?__tn__=%2Cd%2CP-R&eid=ARAK4yLdRXSkkOpJoFqV-0SfVflw3GLweoo0mmd2kG-DHY9UInK-1gJXNKYb288tfw3zIA4bTn9vxZOu', '_system');

    /*  (<any>window).startApp.set({
       "action": "ACTION_VIEW",
       "uri": "https://www.facebook.com/M%C3%B3vil-360-101655521425313/?__tn__=%2Cd%2CP-R&eid=ARAK4yLdRXSkkOpJoFqV-0SfVflw3GLweoo0mmd2kG-DHY9UInK-1gJXNKYb288tfw3zIA4bTn9vxZOu"
     }).start(); */
  }

  public openInsta(): void {
    this.inAppBrowser.create('https://www.instagram.com/movil360/', '_system');

    /* (<any>window).startApp.set({
      "action": "ACTION_VIEW",
      "uri": "https://www.instagram.com/movil360/"
    }).start(); */
  }

}
