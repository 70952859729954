import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavController, NavParams, AlertController, ModalController } from '@ionic/angular';
import { Stripe, StripeCardTokenParams } from '@ionic-native/stripe/ngx';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { Tarjeta } from 'src/app/models/Tarjeta';
import { PagoRealizadoPage } from '../pago-realizado/pago-realizado.page';

@Component({
  selector: 'app-datos-tarjeta',
  templateUrl: './datos-tarjeta.page.html',
  styleUrls: ['./datos-tarjeta.page.scss'],
})
export class DatosTarjetaPage implements OnInit {

  public form: FormGroup;
  public pago: number;

  constructor(public navCtrl: NavController,
    public navParams: NavParams,
    private formBuilder: FormBuilder,
    private stripe: Stripe,
    private utilities: UtilitiesService,
    private api: ApiService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController) {

    this.pago = navParams.get('pago');
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      number: ['', [Validators.required]],
      expMonth: ['', [Validators.required]],
      expYear: ['', [Validators.required]],
      cvc: ['', [Validators.required]],
      /* email: ['', [Validators.required]], */
    });
  }

  public submitForm(): void {
    this.utilities.showLoading('Procesando pago...');
    this.stripe.setPublishableKey(environment.stripePublishableKey);
    let card: StripeCardTokenParams = this.form.value;
    this.stripe.createCardToken(card).then((token) => {
      this.api.crearTarjeta(token).subscribe((nueva_tarjeta: Tarjeta) => {
        console.log(nueva_tarjeta);
        this.api.procesarPago({ precio: this.pago, stripeToken: token.id }).subscribe(response => {
          this.utilities.dismissLoading();
          this.closeModal(true);
          console.log(response);
          this.pagoRealizado();
        }, error => {
          this.utilities.dismissLoading();
          this.closeModal(false);
          this.utilities.showToast('No se ha podido realizar el pago');
          console.log(error);
        })

      }, error => {
        this.utilities.dismissLoading();
        console.log(error);
        this.utilities.showToast("No se ha podido añadir la tarjeta");
      })

    }, error => {
      console.log(error);
      this.utilities.dismissLoading();
      this.closeModal(false);
      this.utilities.showAlert('Error al procesar el pago', 'Hubo un error al procesar el pago. Inténtalo de nuevo más tarde. <br><br>Detalles del error: ' + JSON.stringify(error.message));
    });
  }

  /**
   * Alert de confirmación de compra
   * @param precio 
   */
  public async alertConfirmacion() {
    const alert = await this.alertCtrl.create({
      header: 'Confirmar compra',
      message: '¿Quieres finalizar el pago?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.submitForm();
          }
        }
      ]
    });
    await alert.present();
  }


  public closeModal(exito: boolean): void {
    this.modalCtrl.dismiss({
      'exito': exito
    });
  }


  public async pagoRealizado() {
    const modal = await this.modalCtrl.create({
      component: PagoRealizadoPage,
    });

    await modal.present();
  }


}
