import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { User } from '../models/User';

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilitiesService } from './utilities.service';
import { Storage } from '@ionic/storage';
import { Categoria } from '../models/Categoria';
import { Producto } from '../models/Producto';
import { SubCategoria } from '../models/SubCategoria';
import { Transportista } from '../models/Transportista';
import { Factura } from '../models/Factura';
import { Pedido } from '../models/Pedido';
import { Provincia } from '../models/Provincia';
import { Direccion } from '../models/Direccion';
import { Tarjeta } from '../models/Tarjeta';
import { Historial } from '../models/Historial';


@Injectable({
  providedIn: 'root'
})

export class ApiService {

  public userChanges = new Subject<User>();
  public httpOptions: any;
  public categorias: Categoria[];
  public historiales: Historial[];
  public admin: boolean;

  constructor(public http: HttpClient,
    private utilities: UtilitiesService,
    private storage: Storage) { }

  /**
   * Método para iniciar sesión
   * @param email 
   * @param password 
   */
  public login(user: User) {
    return this.http.post<User>(environment.apiUrl + 'login', user);
  }

  /**
   * Método para el registro básico
   * @param user 
   */
  public register(user: User) {
    return this.http.post(environment.apiUrl + 'signup', user);
  }

  /**
   * Método para recuperar contraseña
   * @param email 
   */
  public forgotPassword(email: string) {
    return this.http.post(environment.apiUrl + 'forgot-password', email);
  }

  //cart storage
  public setCartStorage(cart){
    this.storage.set('cart', cart);
  }
  public getCartStorage() {
    return this.storage.get('cart');
  }

  /**
   * Método para añadir el bearer token a las cabeceras 
   */
  public setTokenToHeaders(token: string): void {

    //Asignar token a las siguientes peticiones
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      })
    };
  }

  /**
   * Guardamos el token de sesion en el storage
   */
  public setTokenStorage(token: string): void {
    //Guardamos el token en el storage
    this.storage.set('api_token', token);
  }

  /**
   * Devolvemos el token del storage 
   */
  public getTokenStorage() {
    return this.storage.get('api_token');
  }

  /**
   * Borramos todos los datos del storage
   */
  public clearStorage() {
    this.storage.clear();
    this.storage.set('api_token', null);
    this.storage.set('cart', null);
  }


  /**
   * Método para obtener los datos del usuario
   */
  public getUser(): any {
    return this.http.get<User>(environment.apiUrl + 'user', this.httpOptions);
  }

  /**
   * Método para actualizar los datos del usuario
   * @param user 
   */
  public updateUser(user: User): any {
    this.userChanges.next(user);
    return this.http.post<User>(environment.apiUrl + 'update-user', user, this.httpOptions);
  }

  /**
   * Método para obtener las traducciones
   */
  public getTranslations() {
    return this.http.get(environment.apiUrl + 'traducciones', this.httpOptions);
  }

  /**
    * Guardar el token del dispositivo en el servidor
    * @param tokenRegistro 
    */
  public guardarTokenDeRegistro(tokenRegistro) {
    return this.http.post(environment.apiUrl + 'guardar-token', { registerToken: tokenRegistro, platform: this.utilities.getPlatform() }, this.httpOptions);
  }

  /**
* Método para procesar el pago
*/
  public procesarPago(params: { precio: number, stripeToken?: any }): any {

    return this.http.post(environment.apiUrl + 'pago', params, this.httpOptions);
  }

  // ====================== Métodos añadidos ==========================

  /**
   * Obtenemos todas las categorias
   */
  public getApiCategorias(): any {
    return this.http.get<Categoria[]>(environment.apiUrl + 'categorias', this.httpOptions);
  }

  /**
   * Obtenemos todas las categorias
   */
  public getCategorias(): Categoria[] {
    if (!this.categorias)
      this.getApiCategorias().subscribe((ca: Categoria[]) => {
        this.categorias = ca;
      });
    return this.categorias;
  }
  /**
   * Obtenemos los productos según categoría
   * @param id_categoria 
   */
  public getProductosCategoria(id_categoria: number): any {
    return this.http.get<Producto[]>(environment.apiUrl + 'productos-categoria/' + id_categoria, this.httpOptions);
  }

  /**
   * Obtenemos los productos según categoría
   * @param id_categoria 
   */
  public getProductosSubCategoria(id_sub_categoria: number): any {
    return this.http.get<Producto[]>(environment.apiUrl + 'productos-subcategoria/' + id_sub_categoria, this.httpOptions);
  }

  /**
   * Obtenemos las subcategorías según categoría
   * @param id_categoria 
   */
  public getSubCategorias(id_categoria: number): any {
    return this.http.get<SubCategoria[]>(environment.apiUrl + 'sub-categorias/' + id_categoria, this.httpOptions);
  }

  /**
   * Loguearse con facebook
   * @param user 
   */
  public loginFacebook(user: User): any {
    return this.http.post<User>(environment.apiUrl + 'login-facebook', user, this.httpOptions);
  }

  /**
   * Loguearse con google
   * @param user 
   */
  public loginGoogle(user: User): any {
    return this.http.post<User>(environment.apiUrl + 'login-google', user, this.httpOptions);
  }

  public getProducto(id_producto: number): any {
    return this.http.get<Producto>(environment.apiUrl + 'producto/' + id_producto, this.httpOptions);
  }

  public getTransportistas(): any {
    return this.http.get<Transportista[]>(environment.apiUrl + 'transportistas', this.httpOptions);
  }

  public getContrarrembolso(): any {
    return this.http.get<number>(environment.apiUrl + 'contrarrembolso', this.httpOptions);
  }

  public nuevaFactura(factura: Factura): any {
    return this.http.post<Factura>(environment.apiUrl + 'nueva-factura', factura, this.httpOptions);
  }

  public crearPedidos(id_factura: number, pedidos: Pedido[]): any {
    return this.http.post<Pedido[]>(environment.apiUrl + 'crear-pedidostienda', { id_factura: id_factura, pedidos: pedidos }, this.httpOptions);
  }
  public addtotienda(id_factura: number, pedido_id: number): any {
    return this.http.post(environment.apiUrl + 'addtotienda', { id_factura: id_factura, pedido_id: pedido_id }, this.httpOptions);
  }

  public getProvincias(): any {
    return this.http.get<Provincia[]>(environment.apiUrl + 'provincias', this.httpOptions);
  }

  public getNombreProductos(): any {
    return this.http.get(environment.apiUrl + 'nombre-productos', this.httpOptions);
  }
  public getLetras(): any {
    return this.http.get(environment.apiUrl + 'letras', this.httpOptions);
  }
  public getTallascamiseta(): any {
    return this.http.get(environment.apiUrl + 'tallascamiseta', this.httpOptions);
  }
  public getTallasbody(): any {
    return this.http.get(environment.apiUrl + 'tallasbody', this.httpOptions);
  }
  public getNameProductos(producto_id): any {
    return this.http.get(environment.apiUrl + 'name-productos/' + producto_id, this.httpOptions);
  }

  public getNombreProductosCategoria(id: number): any {
    return this.http.get(environment.apiUrl + 'nombre-productos-categoria/' + id, this.httpOptions);
  }

  public getDirecciones(): any {
    return this.http.get(environment.apiUrl + 'direcciones', this.httpOptions);
  }
  public getFacturas(): any {
    return this.http.get(environment.apiUrl + 'facturas', this.httpOptions);
  }
  public getFactura(id_factura: number): any {
    return this.http.get(environment.apiUrl + 'factura/' + id_factura, this.httpOptions);
  }
  public getPedido(id_factura: number): any {
    return this.http.get(environment.apiUrl + 'pedido/' + id_factura, this.httpOptions);
  }
  public getPedidos(): any {
    return this.http.get(environment.apiUrl + 'pedidos', this.httpOptions);
  }

  public updateDireccion(direccion: Direccion): any {
    return this.http.post(environment.apiUrl + 'update-direccion', direccion, this.httpOptions);
  }

  public borrarDireccion(direccion: Direccion): any {
    return this.http.post(environment.apiUrl + 'borrar-direccion', direccion, this.httpOptions);
  }
  public borrarDirecciones(): any {
    return this.http.get(environment.apiUrl + 'borrar-direcciones', this.httpOptions);
  }


  public nuevaDireccion(direccion: Direccion): any {
    return this.http.post(environment.apiUrl + 'crear-direccion', direccion, this.httpOptions);
  }

  public asignarDireccion(direccion: Direccion): any {
    return this.http.post(environment.apiUrl + 'asignar-direccion', direccion, this.httpOptions);
  }

  public asignarTarjeta(tarjeta: Tarjeta): any {
    return this.http.post(environment.apiUrl + 'asignar-tarjeta', tarjeta, this.httpOptions);
  }

  public getCards(): any {
    return this.http.get(environment.apiUrl + 'tarjetas', this.httpOptions);
  }

  public crearTarjeta(stripeToken: any): any {
    return this.http.post(environment.apiUrl + 'crear-tarjeta', { stripeToken: stripeToken.id }, this.httpOptions);
  }

  public borrarTarjeta(cardToken: any): any {
    return this.http.post(environment.apiUrl + 'borrar-tarjeta', { cardToken: cardToken }, this.httpOptions);
  }
  public borrarTarjetas(): any {
    return this.http.get(environment.apiUrl + 'borrar-tarjetas', this.httpOptions);
  }

  public getCategoriasProducto(product_id: number): any {
    return this.http.get(environment.apiUrl + 'categorias-producto/' + product_id, this.httpOptions);
  }
  public getMarcas(): any{
    return this.http.get(environment.apiUrl + 'marcas', this.httpOptions);
  }
  public getModelos(): any{
    return this.http.get(environment.apiUrl + 'modelos', this.httpOptions);
  }
  public getTipos(): any{
    return this.http.get(environment.apiUrl + 'tipos', this.httpOptions);
  }
  public getSeries(): any{
    return this.http.get(environment.apiUrl + 'series', this.httpOptions);
  }
}
