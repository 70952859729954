import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Direccion } from 'src/app/models/Direccion';
import { Tarjeta } from 'src/app/models/Tarjeta';
import { ApiService } from 'src/app/services/api.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-pago-realizado',
  templateUrl: './pago-realizado.page.html',
  styleUrls: ['./pago-realizado.page.scss'],
})
export class PagoRealizadoPage implements OnInit {

  public user: User;
  public tarjetas: Tarjeta[];

  constructor(private navCtrl: NavController,
    private apiService: ApiService,
    private utilities: UtilitiesService,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    this.apiService.getUser().subscribe((user: User) => {
      this.user = user;
    });

    this.apiService.borrarDirecciones().subscribe(response => {
      console.log("Direcciones Borrados");
      
    }, error => {
      console.log("No se ha podido borrar las direcciones");
    });
    this.apiService.getCards().subscribe(res => {
      this.tarjetas = res.sources.data;
      this.tarjetas.forEach(ans =>{

        this.apiService.borrarTarjeta(ans.id).subscribe(response => {
          console.log("Tarjetas Borrados");
          
        }, error => {
          console.log("No se ha podido borrar las Tarjetas");
        });

      })
    });

    
  }

  public volver(): void {
    this.navCtrl.navigateRoot('/categorias');
    this.modalCtrl.dismiss().then(() => {
      this.modalCtrl.dismiss().then(() => {
        this.modalCtrl.dismiss();
      });
    })
  }

}
