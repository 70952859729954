import { Component, OnInit } from '@angular/core';
import { Direccion } from 'src/app/models/Direccion';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { Provincia } from 'src/app/models/Provincia';

@Component({
  selector: 'app-direccion-modal',
  templateUrl: './direccion-modal.page.html',
  styleUrls: ['./direccion-modal.page.scss'],
})
export class DireccionModalPage implements OnInit {

  public provincias: Provincia[];
  public paises: any = [{ id: 1, nombre: 'España Península' }, { id: 2, nombre: 'Portugal' }, { id: 3, nombre: 'Baleares' }, { id: 4, nombre: 'Canarias' }];
  public provinciasFiltradas: Provincia[];

  public direccion: Direccion = {
    nombre: null,
    direccion_postal: null,
    codigo_postal: null,
    localidad: null,
    pais_id: null,
    movil: null,
    provincia_id: 1,
    desplegado: false
  };

  constructor(private modalCtrl: ModalController,
    private apiService: ApiService) { }

  public ngOnInit() {
    this.apiService.getProvincias().subscribe((provincias: Provincia[]) => {
      this.provincias = provincias;
      this.provinciasFiltradas= [...provincias];
    });
  }

  public guardarDireccion(): void {
    this.modalCtrl.dismiss({
      'direccion': this.direccion
    });
  }

  public refreshProvincias(dir: Direccion) {
    console.log(dir);

    this.provinciasFiltradas = this.provincias.filter(x => x.pais_id == dir.pais_id);
    console.log(this.provinciasFiltradas);
    
  }

}
