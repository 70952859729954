import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'logout', redirectTo: 'login', pathMatch: 'full' },
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule) },
  { path: 'direcciones', loadChildren: () => import( './pages/direcciones/direcciones.module').then (m => m.DireccionesPageModule) },
  { path: 'historial', loadChildren: () => import( './pages/historial/historial.module').then (m => m.HistorialPageModule) },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) },
  { path: 'forgot-password', loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'carrito', loadChildren: () => import('./pages/carrito/carrito.module').then(m => m.CarritoPageModule) },
  //Categorias
  { path: 'categorias', loadChildren: () => import('./pages/categorias/categorias.module').then(m => m.CategoriasPageModule) },
  { path: 'categorias/detalle-producto/:id_producto', loadChildren: () => import('./pages/detalleproducto/detalleproducto.module').then(m => m.DetalleproductoPageModule) },
  { path: 'categorias/litproductos/:id_categoria/personalizacion', loadChildren: () => import('./pages/personalizacion/personalizacion.module').then(m => m.PersonalizacionPageModule) },
  { path: 'categorias/sub-categorias/:id_categoria', loadChildren: () => import('./pages/sub-categorias/sub-categorias.module').then(m => m.SubCategoriasPageModule) },
  { path: 'historial/historialfactura/:id_factura', loadChildren: () => import('./pages/historialfactura/historialfactura.module').then(m => m.HistorialFacturaPageModule) },
  { path: 'categorias/sub-categorias/:id_categoria/detalle-producto/:id_producto', loadChildren: () => import('./pages/detalleproducto/detalleproducto.module').then(m => m.DetalleproductoPageModule) },
  { path: 'categorias/sub-categorias/:id_categoria/litproductos/:id_categoria', loadChildren: () => import('./pages/litproductos/litproductos.module').then(m => m.LitproductosPageModule) }, { path: 'litproductos', loadChildren: () => import('./pages/litproductos/litproductos.module').then(m => m.LitproductosPageModule) },
  { path: 'categorias/litproductos/:id_categoria', loadChildren: () => import('./pages/litproductos/litproductos.module').then(m => m.LitproductosPageModule) }, { path: 'litproductos', loadChildren: () => import('./pages/litproductos/litproductos.module').then(m => m.LitproductosPageModule) },
  { path: 'categorias/sub-categorias/:id_categoria/litproductos/:id_categoria/detalleproducto/:id_producto/:personalizable', loadChildren: () => import('./pages/detalleproducto/detalleproducto.module').then(m => m.DetalleproductoPageModule) },
  { path: 'categorias/litproductos/:id_categoria/detalleproducto/:id_producto/:personalizable', loadChildren: () => import('./pages/detalleproducto/detalleproducto.module').then(m => m.DetalleproductoPageModule) },

  { path: 'detalle-producto', loadChildren: () => import('./pages/detalleproducto/detalleproducto.module').then(m => m.DetalleproductoPageModule) },
  { path: 'personalizacion', loadChildren: () => import('./pages/personalizacion/personalizacion.module').then(m => m.PersonalizacionPageModule) },
  { path: 'terminos', loadChildren: () => import('./pages/terminos/terminos.module').then(m => m.TerminosPageModule) },
  { path: 'privacidad', loadChildren: () => import('./pages/privacidad/privacidad.module').then(m => m.PrivacidadPageModule) },
  { path: 'faq', loadChildren: './pages/faq/faq.module#FaqPageModule' },
  { path: 'confirmar-carrito', loadChildren: './pages/confirmar-carrito/confirmar-carrito.module#ConfirmarCarritoPageModule' },
  { path: 'confirmar-pago', loadChildren: './pages/confirmar-pago/confirmar-pago.module#ConfirmarPagoPageModule' },
  { path: 'pago-realizado', loadChildren: './pages/pago-realizado/pago-realizado.module#PagoRealizadoPageModule' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },









];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
