import { Component, OnInit } from '@angular/core';
import { Events, ModalController, NavController } from '@ionic/angular';
import { Carrito } from 'src/app/models/Carrito';
import { Direccion } from 'src/app/models/Direccion';
import { Factura } from 'src/app/models/Factura';
import { User } from 'src/app/models/User';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ConfirmarPagoPage } from '../confirmar-pago/confirmar-pago.page';
import { DatosTarjetaPage } from '../datos-tarjeta/datos-tarjeta.page';
import { DireccionModalPage } from '../direccion-modal/direccion-modal.page';
import { SeleccionarTransportistaPage } from '../seleccionar-transportista/seleccionar-transportista.page';
import {RecogerPage} from '../recoger/recoger.page';

@Component({
  selector: 'app-confirmar-carrito',
  templateUrl: './confirmar-carrito.page.html',
  styleUrls: ['./confirmar-carrito.page.scss'],
})
export class ConfirmarCarritoPage implements OnInit {

  public user: User;
  public carrito: Carrito;

  constructor(private apiService: ApiService,
    private carritoService: CartService,
    private modalCtrl: ModalController,
    private utilities: UtilitiesService,
    private events: Events,
    private navCtrl: NavController) { }

  ngOnInit() {
    this.utilities.showLoading();
    this.events.publish('activar-whatsapp', false);

    this.carrito = this.carritoService.getCarrito();

    this.apiService.getUser().subscribe((user: User) => {
      this.user = user;
      this.apiService.getDirecciones().subscribe((direcciones: Direccion[]) => {
        this.utilities.dismissLoading();
        direcciones.map(x => {
          x.pais_id = Number(x.pais_id);
          x.provincia_id = Number(x.provincia_id);
        })
        this.user.direcciones = direcciones;
        console.log(user);

      });
    }, error => {
      console.log(error);
    });

  }


  public async modalPago() {
    const modal = await this.modalCtrl.create({
      component: DatosTarjetaPage,
      componentProps: {
        pago: this.carrito.precio_total
      },
      cssClass: 'select-modal'
    });

    await modal.present();

    modal.onDidDismiss().then((response) => {
      console.log(response);
      if (response.data.exito) {
        console.log(response.data.exito);

      }
    });
  }


  public asignarDireccion(direccion: Direccion): void {
    this.utilities.showLoading("Asignando dirección");
    this.apiService.asignarDireccion(direccion).subscribe(response => {
      this.user.direcciones = this.user.direcciones.map(x => {
        x.asignada = false;
        return x;
      });
      direccion.asignada = true;
      this.utilities.showToast("Dirección asignada correctamente");
      this.utilities.dismissLoading();
    }, error => {
      this.utilities.showToast("No se ha podido asignar la dirección");
      this.utilities.dismissLoading();
    });
    direccion.desplegado = false;
  }


  public async nuevaDireccion() {
    const modal = await this.modalCtrl.create({
      component: DireccionModalPage,
      cssClass: 'seleccionar-direccion-modal'
    });

    await modal.present();

    modal.onDidDismiss().then((response) => {
      if (response.data.direccion) {
        this.utilities.showLoading("Creando nueva dirección");
        this.apiService.nuevaDireccion(response.data.direccion).subscribe(response => {
          this.user.direcciones.push(response.direccion);
          this.utilities.dismissLoading();
          this.recogerentienda();
        }, error => {
          this.utilities.dismissLoading();
        });
      }
    });
  }


  public async transportistasModal() {

    const modal = await this.modalCtrl.create({
      component: SeleccionarTransportistaPage,
      cssClass: 'seleccionar-transportista-modal'
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();
    this.carritoService.setTransportista(data.transportista);

  }

  public async opcionesPagoModal() {
    const modal = await this.modalCtrl.create({
      component: ConfirmarPagoPage,
      cssClass: 'confirmar-carrito-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
  }



  public async recogerentienda() {
    const modal = await this.modalCtrl.create({
      component: RecogerPage,
      cssClass: 'confirmar-carrito-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
  }




  
  public enviarPedidos() {
    this.utilities.showLoading("Realizando pedidos");

    let factura: Factura = {
      precio_total: this.carrito.precio_total,
      precio_totaldist: this.carrito.precio_totaldist,
      precio_sin_iva: this.carrito.precio_sin_iva,
      iva_aplicado: this.carrito.iva_aplicado,
      gastos_envio: 0,
      gastos_envio_dist: 0,
      transportista: this.carrito.transportista.id,
      contrarrembolso: this.carrito.contrarrembolso,
      observaciones: this.carrito.observaciones,
      pago_tienda: this.carrito.pago_tienda,
      pago_tarjeta: this.carrito.pago_tarjeta
      
    }

    let direccion = this.user.direcciones.find(x => Number(x.asignada) == 1);

    this.apiService.nuevaFactura(factura).subscribe(response => {
      let id_factura = response.id_factura;
      this.carrito.pedidos = this.carrito.pedidos.map(x => {
        x.direccion_id = direccion.id;
        x.user_id = this.user.id;
        return x;
      });
      this.apiService.crearPedidos(id_factura, this.carrito.pedidos).subscribe(response => {
        this.utilities.showToast("Se han realizado el pedido correctamente");
        this.carritoService.clearCart();
        this.modalCtrl.dismiss();
        this.navCtrl.navigateRoot('/categorias');
        console.log(response);
        this.utilities.dismissLoading();
      }, error => {
        this.utilities.dismissLoading();
        this.utilities.showToast("No se han podido realizar los pedidos");
      })
    }, error => {
      this.utilities.dismissLoading();
      this.utilities.showToast("No se han podido realizar los pedidos");
    })



  }





}
