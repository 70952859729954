import { Component, OnInit } from '@angular/core';
import { Tarjeta } from 'src/app/models/Tarjeta';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-tarjeta-modal',
  templateUrl: './tarjeta-modal.page.html',
  styleUrls: ['./tarjeta-modal.page.scss'],
})
export class TarjetaModalPage implements OnInit {

  public tarjeta: Tarjeta = {
    id: null,
    number:null,
    cvc:null,
    exp_month: null,
    exp_year: null,
    brand: null,
    last4:null,
    country:null
  };

  constructor(private modalCtrl:ModalController) { }

  public ngOnInit():void {
  }

  public guardarTarjeta(): void {
    this.modalCtrl.dismiss({
      'tarjeta': this.tarjeta
    });
  }

}
