import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Carrito } from 'src/app/models/Carrito';
import { Direccion } from 'src/app/models/Direccion';
import { Factura } from 'src/app/models/Factura';
import { Tarjeta } from 'src/app/models/Tarjeta';
import { User } from 'src/app/models/User';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { DatosTarjetaPage } from '../datos-tarjeta/datos-tarjeta.page';
import { PagoRealizadoPage } from '../pago-realizado/pago-realizado.page';
import { TarjetaModalPage } from '../tarjeta-modal/tarjeta-modal.page';
import { StripeCardTokenParams, Stripe } from '@ionic-native/stripe/ngx';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirmar-pago',
  templateUrl: './confirmar-pago.page.html',
  styleUrls: ['./confirmar-pago.page.scss'],
})
export class ConfirmarPagoPage implements OnInit {
  public user: User;
  public carrito: Carrito;
  public opcionPagoSeleccionado: boolean;
  public tarjetas: Tarjeta[];
  public direcciones: Direccion[] = [];
  public isLoading: boolean = false;
  public default_source: string;
  public pedido_id: number;

  constructor(private apiService: ApiService,
    private carritoService: CartService,
    private modalCtrl: ModalController,
    private utilities: UtilitiesService,
    private navCtrl: NavController,
    private stripe: Stripe,
    private alertCtrl: AlertController) { }

  ngOnInit() {
    this.carrito = this.carritoService.getCarrito();
    this.apiService.getUser().subscribe((user: User) => {
      this.user = user;
    });
    this.apiService.getDirecciones().subscribe((direcciones: Direccion[]) => {
      this.direcciones = direcciones;
      console.log(this.direcciones);

    }, error => {
      console.log(error);
    })
    this.apiService.getCards().subscribe(res => {
      this.default_source = res.default_source;
      this.tarjetas = res.sources.data;
    }, error => {
      console.log(error);
    })

  }

  public seleccionarPago(tipo: string): void {
    this.opcionPagoSeleccionado = true;
    if (tipo == "tarjeta") {
      this.carritoService.setContrarrembolso(false);
      this.carritoService.setTarjeta(true);
      this.carritoService.setRecoger(true);
      this.carritoService.setTienda(false);
      console.log("pago con tarjeta");
    } 
    if(tipo == "tienda") {
      this.carritoService.setRecoger(true);
      this.carritoService.setContrarrembolso(false);
      this.carritoService.setTarjeta(false);
      this.carritoService.setTienda(true);
      console.log("pago en tienda");

    }

  }


  public async terminarCompra() {
    if (Number(this.user.role_id) == 4) {
      if (!this.user.codigo || !this.carrito.codigo || (this.user.codigo != this.carrito.codigo)) {
        this.utilities.showToast("Código no válido, consulta con el administrador");
        console.log(this.user.codigo);
        console.log(this.carrito.codigo);
        return;
      }
    }

    if (!this.user.phone) {
      this.utilities.showToast("Tienes que introducir un teléfono en tu perfil");
      this.navCtrl.navigateRoot('/profile');
    } else {

      const alert = await this.alertCtrl.create({
        header: 'Finalizar Compra',
        message: '¿Seguro que quieres finalizar la compra?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
            }
          }, {
            text: 'Comprar',
            handler: () => {
              this.isLoading = true;
              if (this.carrito.contrarrembolso) {
                this.enviarPedidos(false);
              } else {
                this.apiService.getUser().subscribe((user: User) => {
                  if (Number(user.role_id) == 4) {
                    if (!user.customer_id)
                      this.modalPago();
                    else {
                      this.apiService.getCards().subscribe(res => {
                        this.tarjetas = res.sources.data;
                        if (this.tarjetas.length == 0) {
                          this.modalPago();
                          return;
                        }

                        this.utilities.showLoading("Procesando pago...");

                        this.apiService.procesarPago({ precio: this.carrito.precio_totaldist, stripeToken: user.customer_id }).subscribe(response => {
                          this.utilities.dismissLoading();
                          this.enviarPedidos(false);
                          this.carritoService.clearCart();
                          this.navCtrl.navigateRoot('/categorias');
                          console.log(response);
                        }, error => {
                          this.utilities.dismissLoading();
                          this.utilities.showToast('No se ha podido realizar el pago');
                          console.log(error);
                        })
                      }, error => {
                        console.log(error);
                      })
                    }
                  } else {
                    if (!user.customer_id)
                      this.modalPago();
                    else {
                      this.apiService.getCards().subscribe(res => {
                        this.tarjetas = res.sources.data;
                        if (this.tarjetas.length == 0) {
                          this.modalPago();
                          return;
                        }

                        this.utilities.showLoading("Procesando pago...");

                        this.apiService.procesarPago({ precio: this.carrito.precio_total, stripeToken: user.customer_id }).subscribe(response => {
                          this.utilities.dismissLoading();
                          this.enviarPedidos(false);
                          this.carritoService.clearCart();
                          this.navCtrl.navigateRoot('/categorias');
                          console.log(response);
                        }, error => {
                          this.utilities.dismissLoading();
                          this.utilities.showToast('No se ha podido realizar el pago');
                          console.log(error);
                        })
                      }, error => {
                        console.log(error);
                      })
                    }
                  }
                }, error => {
                  if (error.status == 401) {
                    this.utilities.showToast("Debes estar logueado para realizar los pedidos");
                    this.navCtrl.navigateRoot("/cover-page");
                  }
                });
              }
            }
          }
        ]
      });

      await alert.present();
    }

  }

  public enviarPedidos(empresa: boolean) {

    let factura: Factura = {
      precio_total: this.carrito.precio_total,
      precio_totaldist: this.carrito.precio_totaldist,
      precio_sin_iva: this.carrito.precio_sin_iva,
      iva_aplicado: this.carrito.iva_aplicado,
      gastos_envio: this.carrito.gastos_envio,
      gastos_envio_dist: this.carrito.gastos_envio_dist,
      transportista: this.carrito.transportista.id,
      contrarrembolso: this.carrito.contrarrembolso,
      recoger: this.carrito.recoger,
      observaciones: this.carrito.observaciones,
      pago_tienda: this.carrito.pago_tienda,
      pago_tarjeta: this.carrito.pago_tarjeta
    }

    let direccion = this.direcciones.find(x => Number(x.asignada) == 1);

    this.apiService.nuevaFactura(factura).subscribe(response => {
      let id_factura = response.id_factura;
      this.carrito.pedidos = this.carrito.pedidos.map(x => {
        x.direccion_id = direccion.id;
        x.direccion_nombre = direccion.nombre;
        x.direccion_postal = direccion.direccion_postal;
        x.localidad = direccion.localidad;
        x.codigo_postal = direccion.codigo_postal;
        x.telefono_postal = direccion.movil;
        x.user_id = this.user.id;
        return x;
      });

      this.apiService.crearPedidos(id_factura, this.carrito.pedidos).subscribe(response => {
       let pedido_id = response.id;
        this.pagoRealizado();
        console.log(response);
        this.apiService.borrarDirecciones();
        this.apiService.addtotienda(id_factura, pedido_id).subscribe(response=> {
          console.log(response);
        })

      }, error => {

        this.utilities.showToast("No se han podido realizar los pedidos");
      })
    }, error => {
      console.log("Error al crear la factura");
    })

    this.carritoService.clearCart();
    this.navCtrl.navigateRoot('/categorias');

  }

  public async modalPago() {
    const modal = await this.modalCtrl.create({
      component: DatosTarjetaPage,
      componentProps: {
        pago: this.carrito.precio_total
      },
      cssClass: 'select-modal'
    });

    await modal.present();

    modal.onDidDismiss().then((response) => {
      console.log(response);
      if (response.data.exito) {
        this.enviarPedidos(false);
      }
    });
  }

  public async pagoRealizado() {
    const modal = await this.modalCtrl.create({
      component: PagoRealizadoPage,
    });

    await modal.present();
  }
  public asignarTarjeta(tarjeta: Tarjeta): void {
    this.utilities.showLoading("Asignando tarjeta");
    this.apiService.asignarTarjeta(tarjeta).subscribe(response => {
      console.log(response)
      this.utilities.showToast("Tarjeta asignada correctamente");
      this.utilities.dismissLoading();
    }, error => {
      this.utilities.showToast("No se ha podido asignar la tarjeta");
      this.utilities.dismissLoading();
    });
  }

  public async nuevaTarjeta() {
    const modal = await this.modalCtrl.create({
      component: TarjetaModalPage,
      cssClass: 'seleccionar-tarjeta-modal'
    });

    await modal.present();

    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.stripe.setPublishableKey(environment.stripePublishableKey);
        let tarjeta: Tarjeta = response.data.tarjeta;
        let card: StripeCardTokenParams = {
          number: tarjeta.number,
          expMonth: tarjeta.exp_month,
          expYear: tarjeta.exp_year,
          cvc: tarjeta.cvc
        }
        this.stripe.createCardToken(card).then((token) => {
          console.log(token);
          this.utilities.showLoading("Creando tarjeta");
          this.apiService.crearTarjeta(token).subscribe((nueva_tarjeta: Tarjeta) => {
            this.utilities.dismissLoading();
            this.utilities.showToast("Se ha creado la tarjeta correctamente");
            this.tarjetas.push(nueva_tarjeta);
          }, error => {
            this.utilities.dismissLoading();
            console.log(error);
            this.utilities.showToast("No se ha podido crear la tarjeta");
          })
        }, error => {
          console.log(error);
          this.utilities.showToast("No se ha podido crear la tarjeta");
        });
      }
    });
  }

  public borrarTarjeta(tarjeta: Tarjeta): void {
    this.utilities.showLoading("Borrando tarjeta");
    this.apiService.borrarTarjeta(tarjeta.id).subscribe(response => {
      console.log(response)
      this.tarjetas=this.tarjetas.filter(x=>x.id!=tarjeta.id);
      this.utilities.showToast("Tarjeta borrada correctamente");
      this.utilities.dismissLoading();
    }, error => {
      this.utilities.showToast("No se ha podido borrar la tarjeta");
      this.utilities.dismissLoading();
    });
  }


}
