/**
 * Variables globales de la aplicación en local
 */


export const environment = {
  production: false,

  //Url del endpoint de la api
  iframeUrl:'https://www.imprimetuproducto.com/editortest/',
  apiUrl:'https://www.imprimetuproducto.com/api/auth/',
  //Sender id para las notificaciones push
  senderID: '777777777',

  //Clave de stripe
  stripePublishableKey: 'pk_live_pCLYfytuAz0WlPn5mv7GknCP000pMGMcBh'
};
