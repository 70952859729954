import { User } from 'src/app/models/User';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Transportista } from 'src/app/models/Transportista';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-seleccionar-transportista',
  templateUrl: './seleccionar-transportista.page.html',
  styleUrls: ['./seleccionar-transportista.page.scss'],
})
export class SeleccionarTransportistaPage implements OnInit {

  public user: User;
  public transportistas: Transportista[] = [];
  public isLoading: boolean = true;

  constructor(private apiService: ApiService,
    private modalCtrl: ModalController) { }

  public ngOnInit() {
    this.apiService.getUser().subscribe((user: User) => {
      this.user = user;
    });
    this.apiService.getTransportistas().subscribe((transportistas: Transportista[]) => {
      this.transportistas = transportistas;
      this.isLoading = false;
    }, error => {
      console.log("Error al obtener los transportistas", error);
      this.isLoading = false;
    });
  }

  public seleccionarTransportista(transportista: Transportista): void {
    this.modalCtrl.dismiss({
      'transportista': transportista
    });
  }
}
