import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';
import { Toast } from '@ionic-native/toast/ngx';
import { Push } from '@ionic-native/push/ngx';
import { SeleccionarTransportistaPageModule } from './pages/seleccionar-transportista/seleccionar-transportista.module';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Stripe } from '@ionic-native/stripe/ngx';

import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { registerLocaleData } from '@angular/common';
import { DatosTarjetaPageModule } from './pages/datos-tarjeta/datos-tarjeta.module';
import { Camera } from '@ionic-native/camera/ngx';
import { DireccionModalPageModule } from './pages/direccion-modal/direccion-modal.module';
import { TarjetaModalPageModule } from './pages/tarjeta-modal/tarjeta-modal.module';
import {RecogerPageModule} from './pages/recoger/recoger.module';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ConfirmarCarritoPageModule } from './pages/confirmar-carrito/confirmar-carrito.module';
import { ConfirmarPagoPageModule } from './pages/confirmar-pago/confirmar-pago.module';
import { PagoRealizadoPageModule } from './pages/pago-realizado/pago-realizado.module';


registerLocaleData(localeEs, 'es-ES', localeEsExtra);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    DatosTarjetaPageModule,
    DireccionModalPageModule,
    TarjetaModalPageModule,
    RecogerPageModule,
    ConfirmarCarritoPageModule,
    SeleccionarTransportistaPageModule,
    ConfirmarPagoPageModule,
    PagoRealizadoPageModule

  ],
  providers: [
    StatusBar,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Toast,
    Push,
    Stripe,
    Camera,
    Facebook,
    GooglePlus,
    SocialSharing,
    InAppBrowser
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
